import { createContext, useEffect, useState, useContext } from "react";
import { baseURL, useApi, versaoClienteDTO, clientKey } from "./api";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

export const UserAuthContext = createContext({});

export const UserAuthProvider = ({ children }) => {
  const { definirToken, token, triggerVerificacao } = useApi();

  const [logado, setLogado] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dadosAuth, setDadosAuth] = useState(null);
  const [dadosEmpresa, setDadosEmpresa] = useState(null);
  const [modoMesa, setModoMesa] = useState(false);
  const [lojaAberta, setLojaAberta] = useState(false);
  const [urlBucket, setUrlBucket] = useState(null);
  const [dadosUsuarioLogado, setDadosUsuarioLogado] = useState(null);

  function verificarLocalStorage() {
    let tokenLocalStorage = localStorage.getItem("sessao");
    if (tokenLocalStorage) {
      retomarSessao();
    } else {
      iniciarSessaoClient();
    }
  }

  useEffect(() => {
    verificarLocalStorage();
  }, []);

  useEffect(() => {
    setLoading(true);
    verificarLocalStorage();
  }, [triggerVerificacao]);

  useEffect(() => {
    if (dadosEmpresa) {
      verificarLojaAberta();
    }
  }, [dadosEmpresa]);

  useEffect(() => {
    const checkMesaURL = () => {
      const mesaURL = window.location.pathname.includes("/mesa/");
      setModoMesa(mesaURL);
    };
    checkMesaURL();
    window.addEventListener("popstate", checkMesaURL);
    return () => {
      window.removeEventListener("popstate", checkMesaURL);
    };
  }, []);

  async function getDadosEmpresa(token) {
    return new Promise(async (resolve, reject) => {
      let api = axios.create({
        baseURL: baseURL,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const urlAmigavel = window.location.pathname.split("/")[2];

      if (!urlAmigavel) {
        return reject("URL não encontrada");
      }

      try {
        const dadosEmpresa = await api
          .get("/configuracoes-lojas/empresa/url/" + urlAmigavel)
          .catch((error) => {
            console.log(error);
            reject(error);
            throw error;
          });

        if (!dadosEmpresa.data) {
          window.location.href = "/404";
          reject("Empresa não encontrada");
          throw new Error("Empresa não encontrada");
        }

        setDadosEmpresa(dadosEmpresa.data);

        const dadosBucket = await api.get(
          "bucket/bucket/buscar-configuracao/" + dadosEmpresa.data.idEmpresa
        );
        setUrlBucket(dadosBucket.data.urlBucket);
        resolve(dadosEmpresa.data);
      } catch (error) {
        localStorage.removeItem("sessao");
        localStorage.removeItem("CID");

        if (error.response?.status === 404) {
          window.location.href = "/404";
        } else {
          toast.error("Erro ao buscar empresa");
        }

        reject(error.message || "Erro ao buscar empresa");
      }
    });
  }
  async function iniciarSessaoClient() {
    return new Promise(async (resolve, reject) => {
      const urlAmigavel = window.location.pathname.split("/")[2];
      let api = axios.create({
        baseURL: baseURL,
      });

      let stringObject;
      stringObject = localStorage.getItem("sessao_client");
      if (stringObject) {
        try {
          let object = JSON.parse(stringObject);
          setDadosAuth(jwt_decode(object.token));
          definirToken(object.token);
          await getDadosEmpresa(object.token);
          setLogado(false);
          setLoading(false);
          resolve(object.token);
          return;
        } catch (error) {
          localStorage.removeItem("sessao_client");
          setDadosAuth(null);
        }
      }

      try {
        //Obtendo o Token Client somente com id Sessão
        let dadosToken = await api.post("usuario-sessao/client", {
          tipoClient: "WAYMENU",
          clientKey: clientKey,
          versaoClientDTO: versaoClienteDTO,
        });
        if (dadosToken?.data?.token) {
          api.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${dadosToken.data.token}`;
        }

        //Obtendo o Secret do Client
        const dadosSecret = await api.post(
          "aplicacoes-servicos/client-empresa/waymenu",
          {
            "urlAmigavel": urlAmigavel,
            "tipoServico": "WAYMENU",
          }
        );

        //fazendo a chamada para obter o token de sessão do cliente a ser utilizado
        const dadosLogin = await api.post("usuario-sessao/client-empresa", {
          tipoClient: "WAYMENU",
          clientKey: clientKey,
          versaoClientDTO: versaoClienteDTO,
          clientSecret: dadosSecret.data.clientSecret,
        });

        //Salvando o token no LocalStorage
        localStorage.setItem("sessao_client", JSON.stringify(dadosLogin.data));
        definirToken(dadosLogin.data.token);
        setDadosAuth(jwt_decode(dadosLogin.data.token));
        await getDadosEmpresa(dadosLogin.data.token);
        setLoading(false);
        setLogado(false);
        resolve(dadosLogin.data.token);
      } catch (error) {
        console.log(error);
        localStorage.removeItem("sessao");
        toast.error("Erro ao iniciar sessão.");
        await new Promise((resolve) => setTimeout(resolve, 10000)); // Sleep for 30 seconds
        window.location.reload();
        setLoading(false);
        setLogado(false);
        reject(error);
      }
    });
  }

  async function retomarSessao() {
    setLoading(true);
    //checando Token URL
    const urlParams = new URLSearchParams(window.location.search);
    const tokenURL = urlParams.get("sessao");

    if (tokenURL) {
      setDadosAuth(null);
      definirToken(tokenURL);
      await getDadosEmpresa(tokenURL);
      setLogado(true);
      setLoading(false);
      return;
    }

    //checando Token LocalStorage
    let stringObject;
    stringObject = localStorage.getItem("sessao");

    if (stringObject) {
      try {
        let object = JSON.parse(stringObject);
        setDadosAuth(jwt_decode(object.token));
        definirToken(object.token);
        await getDadosEmpresa(object.token);
        setLogado(true);
        setLoading(false);
      } catch (error) {
        localStorage.removeItem("sessao");
        setDadosAuth(null);
        setLogado(false);
        setLoading(false);
        window.location.reload();
      }
    } else {
      setDadosAuth(null);
      setLogado(false);
      setLoading(false);
    }
    setLoading(false);
  }
  console.log(dadosEmpresa, "dadosEmpresa");

  function realizarLogin(cpf, senha) {
    return new Promise((resolve, reject) => {
      console.log(dadosEmpresa.idEmpresa, "testeess");
      let api = axios.create({
        baseURL: baseURL,
      });

      const sanitizedCpf = cpf.replace(/[^a-zA-Z0-9]/g, "");

      api
        .post("usuario-sessao/client/usuario/waymenu", {
          tipoClient: "WAYMENU",
          clientKey: clientKey,
          versaoClientDTO: versaoClienteDTO,
          cpf: sanitizedCpf,
          password: senha,
          idEmpresa: dadosEmpresa.idEmpresa
        })
        .then(async (dadosLogin) => {
          localStorage.setItem("sessao", JSON.stringify(dadosLogin.data));
          localStorage.removeItem("sessao_client");
          definirToken(dadosLogin.data.token);
          setDadosAuth(jwt_decode(dadosLogin.data.token));
          setDadosUsuarioLogado(dadosLogin.data.usuarioLogadoDTO);
          setLoading(false);
          setLogado(true);
          resolve(dadosLogin.data.token);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setLogado(false);
          reject(error);
        });
    });
  }

  function realizarLoginGoogle(uid) {
    return new Promise((resolve, reject) => {
      let api = axios.create({
        baseURL,
      });

      api
        .post("usuario-sessao/client/usuario/waymenu", {
          tipoClient: "WAYMENU",
          clientKey,
          versaoClientDTO: versaoClienteDTO,
          uid,
          idEmpresa: dadosEmpresa.idEmpresa
        })
        .then(async (dadosLogin) => {
          localStorage.setItem("sessao", JSON.stringify(dadosLogin.data));
          definirToken(dadosLogin.data.token);
          setDadosUsuarioLogado(dadosLogin.data.usuarioLogadoDTO);
          setDadosAuth(jwt_decode(dadosLogin.data.token));
          setLoading(false);
          setLogado(true);
          resolve(dadosLogin.data.token);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setLogado(false);
          reject(error);
        });
    });
  }

  function deslogarUsuario() {
    localStorage.removeItem("sessao");
    setDadosAuth(null);
    setLogado(false);
    iniciarSessaoClient();
  }

  function verificarLojaAberta() {
    if (!dadosEmpresa?.funcionamentoEmpresa) {
      setLojaAberta(false);
      return;
    }

    const diasSemana = [
      "segunda-feira",
      "terça-feira",
      "quarta-feira",
      "quinta-feira",
      "sexta-feira",
      "sábado",
      "domingo",
    ];

    let diaSemanaAtual = diasSemana.indexOf(
      dayjs().locale("pt-br").format("dddd")
    );

    let api = axios.create({
      baseURL: baseURL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    api
      .post(
        "configuracoes-lojas/empresa-horario-funcionamento/buscar-todos?page=0&size=100",
        {
          idEmpresa: dadosEmpresa.idEmpresa,
          dia: diaSemanaAtual,
        }
      )
      .then((response) => {
        let aberto = false;

        response.data.content.forEach((horario) => {
          let horaAtual = dayjs();
          let horaAbertura = dayjs(horario.horaAbertura, "HH:mm:ss");
          let horaFechamento = dayjs(horario.horaFechamento, "HH:mm:ss");

          if (
            horaAtual.isAfter(horaAbertura) &&
            horaAtual.isBefore(horaFechamento)
          ) {
            aberto = true;
          }
        });

        setLojaAberta(aberto);
      });
  }

  return (
    <UserAuthContext.Provider
      value={{
        logado,
        dadosAuth,
        dadosEmpresa,
        urlBucket,
        lojaAberta,
        realizarLogin,
        realizarLoginGoogle,
        deslogarUsuario,
        loading,
        setLoading,
        setLogado,
        iniciarSessaoClient,
        modoMesa,
        dadosUsuarioLogado,
      }}
    >
      {children}
    </UserAuthContext.Provider>
  );
};

export const useUserAuth = () => useContext(UserAuthContext);
