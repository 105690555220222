import {
  StyledContainer,
  StyledContainerCardapios,
  ContainerCardapios,
  Cardapio,
  ContainerOrdenacao,
  LinkCustomizado,
} from "./styles.js";
import CardapioHeader from "../../components/cardapio/header/index.js";
import InformacoesRestaurante from "../../components/informacoesRestaurante/index.js";

import AcaiImagem from "../../assets/images/açaí-imagem.svg";
import LanchesImagem from "../../assets/images/lanches-imagem.svg";
import BebidasImagem from "../../assets/images/bebidas-imagem.svg";
import { Link, useLocation, useParams } from "react-router-dom";
import CardapioItens from "./cardapio/cardapioItens/index.js";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { MeusPedidos } from "./meusPedidos/index.js";
import { DetalhesPedido } from "./detalhesPedido/index.js";
import { BotaoCardapioPreenchido } from "../../components/botao/index.js";
import { Cupons } from "./cupons/index.js";
import { useApi } from "../../contexts/api.js";
import { useUserAuth } from "../../contexts/userAuth.js";
import { Carrinho } from "./carrinho/index.js";
import useTamanhoTela from "../../utils/useTamanhoTela.js";
import { toast } from "react-toastify";
import { getUrlCardapios } from "../../utils/getUrlParaCadapios.js";
import { auth } from "../../firebase/firebase.js";
import { getRedirectResult, OAuthProvider } from "firebase/auth";

export function PaginaCardapio() {
  const { tipoCardapio, slugMesa, urlAmigavel } = useParams();
  const { hash } = useLocation();
  const navigate = useNavigate();
  const { dadosAuth, post } = useApi();
  const { dadosEmpresa, urlBucket, modoMesa } = useUserAuth();
  const { largura } = useTamanhoTela();

  const [textoPesquisa, setTextoPesquisa] = useState("");

  function definirComponente() {
    let hashUrl = hash.slice(1);
    console.log(hashUrl);

    if (!tipoCardapio) {
      return <CardapioItens />;
    }

    switch (hashUrl) {
      case "/cardapio":
        return <CardapioItens textoPesquisa={textoPesquisa} />;
      case "/pedidos":
        return <MeusPedidos />;
      case "/carrinho":
        return <Carrinho />;
      case "/detalhes-do-pedido":
        return <DetalhesPedido />;
      case "/vantagens":
        return <Cupons />;
      default:
        return <EscolherCardapio />;
    }
  }

  const [dadosCardapio, setDadosCardapio] = useState([]);
  const [cardapios, setCardapios] = useState([]);
  const [linksBloqueados, setLinksBloqueados] = useState(false);

  useEffect(() => {
    if (modoMesa) {
      if (!slugMesa) {
        if (!toast.isActive("mesaNaoEncontrada")) {
          toast.error("Numero da Mesa não identificado", {
            toastId: "mesaNaoEncontrada",
          });
        }
        let url = getUrlCardapios(urlAmigavel, tipoCardapio, slugMesa);
        navigate(url);
        setLinksBloqueados(true);
      }
    }
  }, [hash]);

  useEffect(() => {
    const fetchRedirectResult = async () => {
      try {
        const result = await getRedirectResult(auth);

        if (result) {
          // Dados do usuário autenticado
          const user = result.user;
          console.log("Usuário:", user);

          // Credenciais do login
          const credential = OAuthProvider.credentialFromResult(result);
          const idToken = credential.idToken; // Token de ID (Firebase)
          const accessToken = credential.accessToken; // Token de acesso (Apple)

          console.log("ID Token:", idToken);
          console.log("Access Token:", accessToken);

          // Informações adicionais
          const additionalInfo = result.additionalUserInfo;
          console.log("Informações adicionais:", additionalInfo);
        } else {
          console.log("Nenhum resultado de redirecionamento encontrado.");
        }
      } catch (error) {
        console.error("Erro ao processar redirecionamento:", error);
      }
    };
    fetchRedirectResult();
  }, []);

  useEffect(() => {
    async function getDadosCardapio() {
      const response = await post(
        "/configuracoes-lojas/cardapio/buscar-todos",
        {
          tipo: "WAYMENU",
          idEmpresa: dadosEmpresa?.idEmpresa,
        }
      );

      let cardapiosFiltrados = response.data.content.filter(
        (cardapio) =>
          cardapio.modulo === tipoCardapio.toUpperCase() ||
          cardapio.modulo === "TODOS"
      );

      setDadosCardapio(cardapiosFiltrados);
    }
    getDadosCardapio();
  }, [dadosEmpresa]);

  function gerarCardapios() {
    let cardapios = [];

    try {
      dadosCardapio &&
        dadosCardapio.forEach((cardapio, index) => {
          cardapios.push(
            <LinkCustomizado
              to={linksBloqueados ? "./" : "./#/cardapio"}
              onClick={() => {
                if (linksBloqueados) {
                  toast.error("Numero da Mesa não identificado");
                }
              }}
              state={cardapio}
              key={`${cardapio.descricao} ${index}`}
            >
              <Cardapio>
                {cardapio.imagem && (
                  <img
                    style={{
                      backgroundImage: `url(${urlBucket}/imagens/CARDAPIO_IMAGEM/${cardapio.imagem})`,
                    }}
                    alt=""
                  />
                )}
                <h1>{cardapio.descricao}</h1>
              </Cardapio>
            </LinkCustomizado>
          );
        });

      setCardapios(cardapios);
    } catch (error) {
      setCardapios([]);
      console.log(error);
    }
  }

  useEffect(() => {
    gerarCardapios();
  }, [dadosCardapio]);

  const EscolherCardapio = () => {
    return (
      <StyledContainerCardapios>
        <h1 className="h1Titulo">Escolha um cardápio</h1>
        <ContainerOrdenacao>{cardapios}</ContainerOrdenacao>
      </StyledContainerCardapios>
    );
  };

  return (
    <>
      <StyledContainer>
        <CardapioHeader
          textoPesquisa={textoPesquisa}
          setTextoPesquisa={setTextoPesquisa}
        />
        <InformacoesRestaurante />
        {definirComponente()}
      </StyledContainer>
    </>
  );
}
