import React, { useState, useRef, useEffect, Fragment } from "react";
import GerarModalResponsivo from "../../gerarModalResponsivo";
import {
  CampoDeSenhaOutlined,
  CampoDeTextoOutlined,
  DescricaoErro,
} from "../../campoInput";
import { BotaoCardapioPreenchido, BotaoCardapioVazado } from "../../botao";
import { ContainerBotoesMaisInfo, ContainerInputs, ContianerBotoes, Linha } from "../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "styled-components";
import { ReactComponent as IconeGoogle } from "../../../assets/images/icones/icon_google.svg";
import { ReactComponent as IconeFacebook } from "../../../assets/images/icones/icon_facebook.svg";
import { ReactComponent as IconeApple } from "../../../assets/images/icones/icon_apple.svg";
import { useUserAuth } from "../../../contexts/userAuth";
import { toast } from "react-toastify";
import { validarCPF } from "../../../utils/validarCPF";
import { GoogleAuthProvider, signInWithPopup, FacebookAuthProvider, OAuthProvider, signInWithRedirect } from "firebase/auth";
import { auth } from "../../../firebase/firebase";

import { useApi } from "../../../contexts/api";

const Login = ({ fechar, loginGoogle, mudarTela }) => {
  const clientKey = process.env.REACT_APP_CLIENT_KEY;
  const { post } = useApi();
  const { dadosEmpresa } = useUserAuth();
  const theme = useTheme();

  const [email, setEmail] = useState("");
  const [erroEmail, setErroEmail] = useState(false);
  const [senha, setSenha] = useState("");
  const [erroSenha, setErroSenha] = useState(false);
  const [lembrarEmail, setLembrarEmail] = useState(false);
  const [modalInformacoesAdicionais, setModalInformacoesAdicionais] = useState(false)
  const [cpf, setCpf] = useState("");
  const [erroCpf, setErroCpf] = useState(false);
  const [celular, setCelular] = useState("");
  const [erroCelular, setErroCelular] = useState(false);
  const [sucessoCelular, setSucessoCelular] = useState(false);
  const [dadosUserGoogle, setDadosUserGoogle] = useState({})
  const { realizarLogin, realizarLoginGoogle } = useUserAuth();
  const [nomeUsuario, setNomeUsuario] = useState("")
  const [emailUsuario, setEmailUsuario] = useState("")
  const [erroEmailUsuario, setErroEmailUsuario] = useState(false);
  const [erroNomeUsuario, setErroNomeUsuario] = useState(false);
  const [sucessoEmail, setSucessoEmail] = useState(false)
  const [sucessoNome, setSucessoNome] = useState(false)
  const [sucessoCpf, setSucessoCpf] = useState(false)


  const [abrirModalRecuperarSenha, setAbrirModalRecuperarSenha] =
    useState(false);

  const [verificarDados, setVerificarDados] = useState({
    nome: null,
    email: null
  })
  const enviarNoEnter = (event) => {
    if (event.key === "Enter") {
      logar();
    }
  };

  function limparCampos() {
    fechar();
    setEmail("");
    setSenha("");
    setErroEmail(false);
    setErroSenha(false);
  }

  function logar() {
    let erro = false;
    if (email === "") {
      erro = true;
      setErroEmail("Preencha o campo de email");
    }

    if (!validarCPF(email)) {
      setErroEmail("Preencha um email válido!")
    }

    if (senha === "") {
      erro = true;
      setErroSenha("Preencha o campo de senha");
    }

    if (erro) {
      return;
    }

    realizarLogin(email.replace(/\D/g, ""), senha)
      .then((res) => {
        if (res.status == 200) {
          fechar();
        }
      })
      .catch((error) => {
        if (error.response?.data?.message) {
          toast.error(error.response.data.message);
          checkErro(error.response.data.message);
        }
      });
  }

  function checkErro(message) {
    if (message.includes("CPF")) {
      setErroEmail(true);
    } else if (message.includes("Senha")) {
      setErroSenha(true);
    }
  }

  const scrollRef = useRef(null);
  function formatarCelular(valor) {
    var valorFormatado = valor.replace(/\D/g, ""); //Remove tudo o que não é dígito

    if (valorFormatado.length == 0) {
      setSucessoCelular(false);
      setErroCelular("Campo obrigatório");
    }

    if (valorFormatado.length <= 9) {
      setSucessoCelular(false);
      setErroCelular("Digite um número válido");
    } else {
      setSucessoCelular(true);
      setErroCelular(false);
    }

    if (valorFormatado.length >= 11) {
      valorFormatado = valorFormatado.substring(0, 11);
    }

    if (valorFormatado.length >= 2) {
      valorFormatado = valorFormatado.replace(/\D/g, "");

      let texto = ") ";

      if (valor.slice(-1) == " ") {
        texto = ") ";
      }
      if (valor.slice(-1) == ")") {
        texto = "";
      }

      valorFormatado =
        "(" +
        valorFormatado.substring(0, 2) +
        texto +
        valorFormatado.substring(2);
    } else {
      if (valorFormatado.length > 0) {
        valorFormatado = "(" + valorFormatado;
      }
    }

    if (valorFormatado.length > 9 && valorFormatado.length < 14) {
      valorFormatado =
        valorFormatado.substring(0, 9) + "-" + valorFormatado.substring(9);
    } else {
      if (valorFormatado.length > 9 && valorFormatado.length >= 14) {
        valorFormatado =
          valorFormatado.substring(0, 10) + "-" + valorFormatado.substring(10);
      }
    }

    return valorFormatado;
  }

  const handleGoogleSignIn = () => {

    const provider = new GoogleAuthProvider()

    signInWithPopup(auth, provider).then(async (result) => {
      const user = result.user
      setNomeUsuario(result.user.displayName)
      setEmail(user.email)
      setVerificarDados({
        nome: result.user.displayName,
        email: user.email
      })
      setSucessoEmail(true)
      setSucessoNome(true)
      await validarLogin(user) ? realizarLoginGoogle(user.uid) : setModalInformacoesAdicionais(true)
      setDadosUserGoogle(result.user)
    }).catch((error) => {
      console.error("Erro ao autenticar com Google", error);
    })
  }


  async function validarLogin(user) {
    try {
      const res = await post(`/usuario-sessao/client/usuario/waymenu/validar/login-google`, {
        idEmpresa: 1,
        email: user.email,
      })

      return res.data.usuarioExiste
    } catch (error) {
      console.error(error);
    }
  }

  async function cadastrarUsuario(user) {

    try {
      const res = await post(
        "/usuario-sessao/client/usuario/waymenu/cadastro?clientKey=" + clientKey,
        {
          nome: nomeUsuario,
          cpf: cpf.replace(/\D+/g, ''),
          email: user.email,
          telefone: celular,
          idEmpresa: 1,
          
          uid: user.uid
        }
      )
        .then((response) => {
          if (response.status === 201) {
            realizarLoginGoogle(user.uid)
          }
        })
        .catch((error) => {
          console.error(error);
          // setLoadingCadastro(false);
          if (error.data?.message) {
            toast.error(error.data?.message);
            // checkErrorAPI(error.data?.message);
          } else {
            toast.error("Erro ao realizar cadastro");
          }
        });
    } catch (error) {
      console.error(error);
    }
  }



  const handleFacebookSignIn = () => {

    const provider = new FacebookAuthProvider()

    signInWithPopup(auth, provider).then(async (result) => {
      const user = result.user
      // await validarLogin(user) ? realizarLoginGoogle(user.uid) : setModalInformacoesAdicionais(true)
      setDadosUserGoogle(result.user)
    }).catch((error) => {
      console.error("Erro ao autenticar com Google", error);
    })
  }
  const handleAppleSignIn = () => {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;
        setNomeUsuario(result.user.displayName)
        setEmailUsuario(result.user.email)
        setVerificarDados({
          nome: result.user.displayName,
          email: result.user.email
        })

        if(result.user.displayName){
          setSucessoNome(true)
        }
       
        if(result.user.email){
          setSucessoEmail(true)
        }
        await validarLogin(user) ? realizarLoginGoogle(user.uid) : setModalInformacoesAdicionais(true)
        setDadosUserGoogle(result.user)
      })
      .catch((error) => {
        console.error("Erro ao autenticar com Apple", error); // Updated error message
      });
  };

  function validarEmail(email) {
    if (email.length < 1) {
      setSucessoEmail(false);
      return;
    }
    var re = /\S+@\S+\.\S+/;
    if (re.test(email)) {
      setSucessoEmail(true);
 
    } else {
    
      setSucessoEmail(false);
    }
  }

  function formatarCpf(valor) {
    var valorFormatado = valor.replace(/\D/g, ""); //Remove tudo o que não é dígito

    if (valorFormatado.length > 11) {
      valorFormatado = valorFormatado.substring(0, 11);
    }
    if (valorFormatado.length > 3) {
      valorFormatado =
        valorFormatado.substring(0, 3) + "." + valorFormatado.substring(3);
    }
    if (valorFormatado.length > 7) {
      valorFormatado =
        valorFormatado.substring(0, 7) + "." + valorFormatado.substring(7);
    }
    if (valorFormatado.length > 11) {
      valorFormatado =
        valorFormatado.substring(0, 11) + "-" + valorFormatado.substring(11);
    }

    if (valorFormatado.length == 11) {
      setErroCpf("");
    }
    
    if (validarCPF(valor.replace(/\D/g, ""))) {
      setSucessoCpf(true);
    } else {
      setSucessoCpf(false);
    }

    setErroCpf(false);
    return valorFormatado;
  }

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, 0);
    }
  }, []);

  return (<>
    <div style={{ overflowY: "scroll" }} ref={scrollRef}>
      <Linha>
        <h2>Faça login</h2>
        <FontAwesomeIcon
          onClick={() => fechar()}
          icon={faXmark}
          style={{ fontSize: 28, color: "red", cursor: "pointer" }}
        />
      </Linha>
      <ContainerInputs>
        <div style={{ width: "100%" }}>
          <CampoDeTextoOutlined
            titulo={"CPF"}
            error={erroEmail}
            value={email}
            onChange={(event) => {
              setEmail(formatarCpf(event.target.value.trim()));
              setErroEmail(false);
            }}
            onKeyDown={enviarNoEnter}
            variant={"outlined"}
            autoFocus={false}
          />
          <DescricaoErro erro={erroEmail} />
        </div>
        <div style={{ width: "100%" }}>
          <CampoDeSenhaOutlined
            titulo={"Senha"}
            error={erroSenha}
            value={senha}
            onChange={(event) => {
              setSenha(event.target.value.trim());
              setErroSenha(false);
            }}
            onKeyDown={enviarNoEnter}
            variant={"outlined"}
            autoFocus={false}
          />
          <DescricaoErro erro={erroSenha} />
        </div>

        <p>Esqueceu a senha</p>
      </ContainerInputs>

      <ContianerBotoes>
        <BotaoCardapioPreenchido
          texto={"Entrar"}
          style={{ width: "100%" }}
          tamanho={"LG"}
          onClick={() => {
            logar();
          }}
        />
        {!loginGoogle && <h3>ou</h3>}
        {!loginGoogle && (
          <BotaoCardapioVazado
            texto={"Criar conta"}
            style={{ width: "100%" }}
            tamanho={"LG"}
            onClick={() => {
              limparCampos();
            }}
          />
        )}
        <BotaoCardapioVazado
          texto={"CONTINUAR COM GOOGLE"}
          style={{ width: "100%", fontSize: 16, fontWeight: 400 }}
          tamanho={"LG"}
          icone={<IconeGoogle />}
          onClick={() => {
            handleGoogleSignIn()
          }}
        />
        {/* <BotaoCardapioVazado
          texto={"CONTINUAR COM FACEBOOK"}
          style={{ width: "100%", fontSize: 16, fontWeight: 400 }}
          tamanho={"LG"}
          icone={<IconeFacebook style={{width: 24, height: 24}} />}
          onClick={() => {
            handleFacebookSignIn()
          }}
        /> */}
        <BotaoCardapioVazado
          texto={"CONTINUAR COM APPLE"}
          style={{ width: "100%", fontSize: 16, fontWeight: 400 }}
          tamanho={"LG"}
          icone={<IconeApple style={{ width: 24, height: 24 }} />}
          onClick={() => {
            handleAppleSignIn()
          }}
        />
        <GerarModalResponsivo controle={modalInformacoesAdicionais} children={() => (
          <div>
            <div style={{ overflowY: "scroll" }} ref={scrollRef}>
              <Linha>
                <h2>Informações adicionais</h2>
                <FontAwesomeIcon
                  onClick={() => {
                    setModalInformacoesAdicionais(false)
                  }}
                  icon={faXmark}
                  style={{ fontSize: 28, color: "red", cursor: "pointer" }}
                />
              </Linha>
              <ContainerInputs>
                {!verificarDados.nome &&
                  <div style={{ width: "100%" }}>
                    <CampoDeTextoOutlined
                      titulo={"Nome"}
                      error={erroNomeUsuario}
                      value={nomeUsuario}
                      sucesso={sucessoNome}
                      onChange={(event) =>
                     {
                      const valor = event.target.value
                      if(!valor){
                        setErroNomeUsuario(true)
                        setSucessoNome(false)
                      } else {
                        setErroNomeUsuario(false)
                        setSucessoNome(true)
                      }
                      setNomeUsuario(event.target.value)
                     }
                      }
                      variant={"outlined"}
                      autoFocus={false}
                    />
                    <DescricaoErro erro={erroEmail} />
                  </div>}
                {!verificarDados.email &&
                  <div style={{ width: "100%" }}>
                    <CampoDeTextoOutlined
                      titulo={"E-mail"}
                      error={erroCelular}
                      value={emailUsuario}
                      sucesso={sucessoEmail}
                      onChange={(event) =>
                      {
                        validarEmail(event.target.value)
                        setEmailUsuario(event.target.value)
                      }
                      }
                      variant={"outlined"}
                      autoFocus={false}
                    />
                    <DescricaoErro erro={erroEmail} />
                  </div>}
                <div style={{ width: "100%" }}>
                  <CampoDeTextoOutlined
                    titulo={"CPF"}
                    error={erroCpf}
                    value={cpf}
                    onChange={(event) => {
                      setCpf(formatarCpf(event.target.value.trim()));
                      setErroCpf(false);
                    }}
                    onKeyDown={enviarNoEnter}
                    sucesso={sucessoCpf}
                    autoFocus={false}
                  />
                  <DescricaoErro erro={erroEmail} />
                </div>
                <div style={{ width: "100%" }}>
                  <CampoDeTextoOutlined
                    titulo={"Celular"}
                    error={erroCelular}
                    value={celular}
                    sucesso={sucessoCelular}
                    onChange={(event) =>
                      setCelular(formatarCelular(event.target.value))
                    }
                    variant={"outlined"}
                    autoFocus={false}
                  />
                  <DescricaoErro erro={erroEmail} />
                </div>

              </ContainerInputs>

              <ContainerBotoesMaisInfo>
                <BotaoCardapioVazado
                  texto={"Cancelar"}
                  style={{ width: "100%", fontSize: 16, fontWeight: 400 }}
                  tamanho={"LG"}
                  onClick={() => {
                    setModalInformacoesAdicionais(false)
                  }}
                />
                <BotaoCardapioPreenchido
                  texto={"Finalizar cadastro"}
                  style={{ width: "100%" }}
                  tamanho={"LG"}
                  onClick={() => {
                    if (!erroCelular && !erroCpf && sucessoEmail && nomeUsuario) {
                      cadastrarUsuario(dadosUserGoogle)
                    }
                  }}
                />
              </ContainerBotoesMaisInfo>
            </div>
          </div>
        )} />
        {loginGoogle && (
          <p style={{ marginTop: 16, marginBottom: 16, fontWeight: 600 }}>
            Não tem conta?{" "}
            <span
              onClick={() => {
                mudarTela("cadastro");
              }}
              style={{
                color: theme.coresCardapio.brand500,
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              Cadastre-se
            </span>
          </p>
        )}
      </ContianerBotoes>
    </div>

  </>

  );
};

export default Login;